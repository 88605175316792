import type { ILayoutRecord } from 'o365.modules.DataObject.Layout.ts';
import context from 'o365.modules.Context.ts';
import { userSession } from 'o365.modules.configs.ts';
import LocalStorageHelper from 'o365.modules.StorageHelpers.ts';

/** Helper class for managing layouts in local storage */
export default class LayoutStore {
    private _storageKey: string;
    private layouts: ILocalStoreLayout = {};

    private get _isLockedKey() { return `${this._storageKey}_locked`; } 

    constructor(pKey: string) {
        this._storageKey = pKey;
        if (userSession.personId == null) { return; }

        const layout = LocalStorageHelper.getItem(this._storageKey);
        if (layout) {
            const layouts = JSON.parse(layout);
            this.layouts = layouts[userSession.personId] ?? {};
            if (this.layouts.contextPersonal) {
                delete this.layouts.contextPersonal;
            }
        } else {
            this.layouts = {};
        }
    }

    /** Attempt to get current layout from local storage */
    getCurrentLayout(): ILayoutRecord | null {
        // Check if there's a layout set by user
        if (this.layouts.setByUser) {
            return this._deepLayoutCopy(this.layouts.setByUser);
        }

        // Search for closest context layout
        let contextLayout: ILayoutRecord | undefined;
        if (this.layouts.context) {
            const currentContextPath = context.idPath.split('/').filter(x => x !== '').map(x => parseInt(x)).reverse();
            currentContextPath.some(contextId => {
                if (this.layouts.context?.[contextId]) {
                    contextLayout = this.layouts.context[contextId];
                    return true;
                } else {
                    return false;
                }
            });
        }

        // Search for context layout personal overrides
        if (contextLayout) {
            if (this.layouts.contextPersonal?.[contextLayout.OrgUnit_ID!]) {
                return this._deepLayoutCopy(this.layouts.contextPersonal[contextLayout.OrgUnit_ID!]);
            } else {
                return this._deepLayoutCopy(contextLayout);
            }
        }

        // Search for personal default layout
        if (this.layouts.personalDefault) {
            return this._deepLayoutCopy(this.layouts.personalDefault);
        }

        // No layoutts in local storage found
        return null;
    }

    /** Get a layout record from the local storage by id */
    getLayoutById(id: number): ILayoutRecord | null {
        if (id == null || this.layouts == null) { return null; }
        switch (id) {
            case this.layouts.setByUser?.ID:
                return this._deepLayoutCopy(this.layouts.setByUser!);
            case this.layouts.personalDefault?.ID:
                return this._deepLayoutCopy(this.layouts.personalDefault!);
            default:
                if (this.layouts.context) {
                    const layoutRecord = Object.values(this.layouts.context).find(layout => layout.ID === id);
                    if (layoutRecord) {
                        return this._deepLayoutCopy(layoutRecord);
                    }
                }
                if (this.layouts.contextPersonal) {
                    const layoutRecord = Object.values(this.layouts.contextPersonal).find(layout => layout.ID === id);
                    if (layoutRecord) {
                        return this._deepLayoutCopy(layoutRecord);
                    }
                }
        }
        return null;
    }

    /** Get a default context layout record from storage by context id */
    getDefaultContextLayout(pContext: number) {
        if (pContext == null || this.layouts == null || this.layouts.context == null) { return null; }
        const layoutRecord = this.layouts.context[pContext];
        return this._deepLayoutCopy(layoutRecord);
    }

    /** 
     * Store a layout record to the local storage
     * @param {ILayoutRecord} pLayout The layout record to store
     * @param {boolean} pUserSet Layout is applied by user and not system. Will store it as setByUser
     */
    storeLayout(pLayout: ILayoutRecord, pUserSet?: boolean) {
        if (userSession.personId == null) { return; }
        if (pLayout.ID == null) {
            console.error(`Can't store layout with no ID`);
            return;
        }

        if (pLayout.Updated) {
            pLayout.Updated = pLayout.Updated.split('.')[0];
        }

        if (pLayout.Person_ID === userSession.personId && pLayout.Default && pLayout.OrgUnit_ID == null) {
            // Personal Default
            this.layouts.personalDefault = pLayout;
        } else if (pLayout.OrgUnit_ID != null && pLayout.Person_ID == null && pLayout.Default) {
            // Context Default
            if (this.layouts.context == null) { this.layouts.context = {}; }
            this.layouts.context[pLayout.OrgUnit_ID] = pLayout;
        } else if (pLayout.OrgUnit_ID != null && pLayout.Person_ID == userSession.personId) {
            // Context Personal Default
            if (this.layouts.contextPersonal == null) { this.layouts.contextPersonal = {}; }
            this.layouts.contextPersonal[pLayout.OrgUnit_ID] = pLayout;
        } else {
            // User Set
            this.layouts.setByUser = pLayout;
        }

        if (pUserSet) {
            this.layouts.setByUser = pLayout;
        }

        this._storeLayouts();
    }

    /** 
     * Delete a layout record from the local storage
     * @param {ILayoutRecord} pLayout The layout record to delete
     */
    deleteLayout(pLayout: Partial<ILayoutRecord>) {
        if (userSession.personId == null) { return; }

        if (pLayout.Person_ID === userSession.personId && pLayout.Default && pLayout.OrgUnit_ID == null) {
            // Personal Default
            delete this.layouts.personalDefault;
        }
        if (pLayout.OrgUnit_ID != null && pLayout.Person_ID == null && pLayout.Default) {
            // Context Default
            if (this.layouts.context == null) { this.layouts.context = {}; }
            delete this.layouts.context[pLayout.OrgUnit_ID];
        } if (pLayout.OrgUnit_ID != null && pLayout.Person_ID == userSession.personId) {
            // Context Personal Default
            if (this.layouts.contextPersonal == null) { this.layouts.contextPersonal = {}; }
            delete this.layouts.contextPersonal[pLayout.OrgUnit_ID];
        } if (pLayout.ID === this.layouts.setByUser?.ID) {
            // User Set
            delete this.layouts.setByUser;
        }

        this._storeLayouts();
    }

    /** Check if the active layout is locked from local storage */
    getIsLocked(pId?: number) {
        if (pId == null) { return false; }
        try {
            const lockedLayouts = localStorage.getItem(this._isLockedKey);
            JSON.parse(lockedLayouts ?? '[]');
            const lockedLayoutsSet = new Set(JSON.parse(lockedLayouts ?? '[]'));
            return lockedLayoutsSet.has(pId);
        } catch (ex) {
            return false;
        }
        // return localStorage.getItem(this._isLockedKey) === 'true';
    }

    /** Set the active layout as locked/unlocked in local storage  */
    setIsLocked(pId?: number, pIsLocked: boolean = true) {
        if (pId == null) { return; }
        let lockedLayoutsSet: Set<number> | null = null;
        try {
            const lockedLayouts = localStorage.getItem(this._isLockedKey);
            JSON.parse(lockedLayouts ?? '[]');
            lockedLayoutsSet = new Set(JSON.parse(lockedLayouts ?? '[]'));
        } catch (ex) {
            lockedLayoutsSet = new Set();
        }

        if (pIsLocked) {
            lockedLayoutsSet.add(pId);
        } else {
            lockedLayoutsSet.delete(pId);
        }

        if (lockedLayoutsSet.size > 0) {
            localStorage.setItem(this._isLockedKey, JSON.stringify(Array.from(lockedLayoutsSet)));

        } else {
            localStorage.removeItem(this._isLockedKey);

        }
    }

    removeUserSetLayout() {
        if (this.layouts.setByUser == null) { return; }
        delete this.layouts.setByUser;
        this._storeLayouts();
    }

    /** Update the layouts stored in local storage */
    private _storeLayouts() {
        if (userSession.personId == null) { return; }

        // Get entire layouts map
        const layoutJson = LocalStorageHelper.getItem(this._storageKey);
        let layoutsMap: Record<string, ILocalStoreLayout> = {};
        if (layoutJson) {
            layoutsMap = JSON.parse(layoutJson);
        }

        // Set or delete layouts for current person id
        if (Object.keys(this.layouts).length === 0) {
            delete layoutsMap[userSession.personId];
        } else {
            layoutsMap[userSession.personId] = this.layouts;
        }

        // Update the map in local storage
        if (Object.keys(layoutsMap).length === 0) {
            LocalStorageHelper.removeItem(this._storageKey);
        } else {
            LocalStorageHelper.setItem(this._storageKey, JSON.stringify(layoutsMap));
        }
    }

    /** Make a copy of the layout */
    private _deepLayoutCopy(pLayout: ILayoutRecord): ILayoutRecord;
    private _deepLayoutCopy(pLayout?: ILayoutRecord): ILayoutRecord | null {
        if (pLayout) {
            return {...pLayout};
        }
        return null;
    }
}

interface ILocalStoreLayout {
    /** Explicitly set layout by the user */
    setByUser?: ILayoutRecord
    /** Personal default layout */
    personalDefault?: ILayoutRecord,
    /** Context layouts, keys are context ids */
    context?: Record<number, ILayoutRecord>,
    /** Personal overrides for context layouts, keys are context ids */
    contextPersonal?: Record<number, ILayoutRecord>,
}